table {
    margin-top: 0px !important;
}

.card-painel .card-content li a,
.card-content li {
    font-size: 13px;
}

.tabela-resultado-leilao td {
    font-size: 11px;
}


@media (max-width: 767px) {
    .modal-resultado-leilao {
        width: 97% !important;
        max-width: 97% !important;
    }
}

/* Estilo para desktops */
@media (min-width: 768px) {
    .modal-resultado-leilao {
        max-width: 550px !important;
        width: 550px !important;
    }
}

.metade-escala nav {
    transform: scale(0.5);
    width: 200% !important;
    translate: -25%;
}


.botao-abrir-modal-detalhe {
    width: 35px !important;
    height: 35px !important;
}

@media (max-width: 767px) {
    .botoes-iniciais button {
        margin-bottom: 10px;
        width: 100%;
    }
}

button.primary i {
    color: white;
}

button.primary:hover i {
    color: black;
}

.header-mobile {
    font-weight: bold;
}

/* Ocultar a classe header-mobile em telas maiores que 768px */
@media only screen and (min-width: 768px) {
    .header-mobile {
        display: none;
    }
}

/* Mostrar a classe header-mobile em telas menores que 768px */
@media only screen and (max-width: 768px) {
    .header-mobile {
        display: inline;
    }
}

.br-cookiebar .br-modal {
    background: transparent;
}